export interface ICategories {
  type: string;
}

export const Categories: ICategories[] = [
  { type: "Gavekort" },
  { type: "Klær" },
  { type: "Sko" },
  { type: "Leker" },
  { type: "Annet" },
];
